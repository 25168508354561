// Core variables
$dark-color: #000;
$light-color: #fff;

// Global
$html-font-size: 10px;
$html-line-height: 1.428571429;
$body-bg: $light-color;
$body-font-color: lighten($dark-color, 5%);
$font-size: 1.4rem;
$font-size-sm: 1.2rem;
$font-size-lg: 1.6rem;

// Fonts
$body-font-family:  'Open Sans', sans-serif;
$title-font-family: 'Poppins', sans-serif;
$cjk-font-family: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Hiragino Kaku Gothic Pro", Meiryo, "Malgun Gothic", $body-font-family;

// Sizes
$layout-padding: 1rem;
$layout-padding-lg: 1.5rem;
$control-min-width: 18rem;
$border-radius: .2rem;
$border-width: .1rem;

// Colors
// Core colors
$primary-color: #7FAD21;
$primary-color-dark: darken($primary-color, 3%);
$primary-color-light: lighten($primary-color, 3%);
$secondary-color: lighten($primary-color, 40%);
$secondary-color-dark: darken($secondary-color, 3%);
$secondary-color-light: lighten($secondary-color, 3%);

$link-color: $primary-color;
$link-color-dark: darken($link-color, 10%);

// Gray colors
$gray-color: lighten($dark-color, 40%);
$gray-color-dark: darken($gray-color, 20%);
$gray-color-light: lighten($gray-color, 20%);

$border-color: lighten($gray-color-light, 3%);
$border-color-dark: darken($border-color, 15%);
$bg-color: lighten($dark-color, 66%);
$bg-color-dark: darken($bg-color, 3%);
$bg-color-light: $light-color;

// Control colors
$control-color-success: #32b643;
$control-color-warning: #ffb700;
$control-color-error: #e85600;

// Other colors
$code-color: #e06870;
$highlight-color: #ffe9b3;

// Responsive breakpoints
$size-xs: 480px;
$size-sm: 600px;
$size-md: 840px;
$size-lg: 960px;
$size-xl: 1280px;
$size-2x: 1440px;

// Z-index
$zindex-0: 1;
$zindex-1: 100;
$zindex-2: 200;
$zindex-3: 300;
$zindex-4: 400;

// Unit sizes
$unit-o: .05rem !default;
$unit-h: .1rem !default;
$unit-1: .2rem !default;
$unit-2: .4rem !default;
$unit-3: .6rem !default;
$unit-4: .8rem !default;
$unit-5: 1rem !default;
$unit-6: 1.2rem !default;
$unit-7: 1.4rem !default;
$unit-8: 1.6rem !default;
$unit-9: 1.8rem !default;
$unit-10: 2rem !default;
$unit-12: 2.4rem !default;
$unit-16: 3.2rem !default;
