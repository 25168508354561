// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark-color;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  margin-top: 0;
  font-family: $title-font-family;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3.2rem;
}
h3 {
  font-size: 2.6rem;
}
h4 {
  font-size: 2.2rem;
}
h5 {
  font-size: 1.8rem;
}
h6 {
  font-size: 1.5rem;
}
p {
  line-height: 2.4rem;
  margin: 0 0 1rem;
}
a,
ins,
u {
  text-decoration-skip: ink edges;
}
blockquote {
  border-left: .2rem solid $border-color;
  margin-left: 0;
  padding: 1rem 2rem;

  p:last-child {
    margin-bottom: 0;
  }

  cite {
    color: $gray-color;
  }
}
ul,
ol {
  margin: 2rem 0 2rem 2rem;
  padding: 0;

  ul,
  ol {
    margin: 1.5rem 0 1.5rem 2rem;
  }

  li {
    margin-top: 1rem;
  }
}
ul {
  list-style: disc inside;

  ul {
    list-style-type: circle;
  }
}
ol {
  list-style: decimal inside;

  ol {
    list-style-type: lower-alpha;
  }
}
dl {
  dt {
    font-weight: bold;
  }
  dd {
    margin: .5rem 0 1.5rem 0;
  }
}
mark {
  @include label-base();
  @include label-variant($body-font-color, $highlight-color);
}
kbd {
  @include label-base();
  @include label-variant($light-color, $dark-color);
}
abbr[title] {
  border-bottom: .1rem dotted;
  cursor: help;
  text-decoration: none;
}
